<template>
  <div id="Case">
    <h2>北京市陈经纶中学</h2>
    <!-- <p>Beijing ChenJingLun High School</p> -->
    <div class="case-box">
      <img
        src="../../../assets/images/partnersCase/cases/case2_item1.png"
        alt=""
      />
      <p>
        作为集团化学校，北京市陈经纶中学一校二十一址，形成了分校地域广、师生人员多、学段跨度大、人员流动快以及发展不均衡的典型集团化办学特征。这也决定了学校必须依托智慧校园才能实现跨时空一体管理。方案凭借集团化办学的中小学大数据SaaS服务平台，帮助陈经纶中学建设成一网+多端(PCWeb端、PC客户端、钉钉端、微信端)的网络平台应用环境。解决了学校在长期的信息化建设中形成的“信息孤岛”现象，更有效连通区域及学校长期以来建设的各业务系统中的数据。同时以云计算、大数据、物联网、人工智能等新技术为手段，满足了学校管理者、教师、学生和家长在家校共育中的需求，提高了办公、学习以及沟通效率。
      </p>
      <img
        src="../../../assets/images/partnersCase/cases/case2_item2.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      margin: 50px 0;
    }
    img {
      width: 40%;
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      img {
        width: 100%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;

    p {
      font-size: 14px;
      line-height: 28px;
      margin: 30px 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    &::after {
      left: 40%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 40px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      font-size: 14px;
      &::after {
        left: 42%;
        margin-top: 35px;
      }
    }
  }
}
</style>
